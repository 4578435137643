// Generated by Framer (78454af)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["muVlMopJH", "Ldsrs5v0b"];

const serializationHash = "framer-jLCc9"

const variantClassNames = {Ldsrs5v0b: "framer-v-liwg20", muVlMopJH: "framer-v-109ttr2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {duration: 0, type: "tween"}

const transition2 = {delay: 0, duration: 0.8, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {End: "Ldsrs5v0b", Start: "muVlMopJH"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "muVlMopJH"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "muVlMopJH", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearf382t5 = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 2000)
})

const onAppeargvj7gj = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 4000)
})

useOnVariantChange(baseVariant, {default: onAppearf382t5, Ldsrs5v0b: onAppeargvj7gj})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({Ldsrs5v0b: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-109ttr2", className, classNames)} data-framer-name={"Start"} data-highlight layoutDependency={layoutDependency} layoutId={"muVlMopJH"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({Ldsrs5v0b: {"data-framer-name": "End"}}, baseVariant, gestureVariant)}><motion.div className={"framer-7995yt"} data-framer-name={"Graphic"} layoutDependency={layoutDependency} layoutId={"tYPEmYEkm"} style={{backgroundColor: "var(--token-fbb46a62-288b-442b-8059-5c9249521eb6, rgba(23, 23, 23, 0.05))", filter: "blur(10px)", mask: "url('https://framerusercontent.com/images/Pcw6GmZUb9oGBvflIVyiKXKvxY.svg') alpha no-repeat center / cover add", opacity: 0.7, WebkitFilter: "blur(10px)", WebkitMask: "url('https://framerusercontent.com/images/Pcw6GmZUb9oGBvflIVyiKXKvxY.svg') alpha no-repeat center / cover add"}}/><motion.div className={"framer-nfnkyo"} data-framer-name={"Blur"} layoutDependency={layoutDependency} layoutId={"WptOEbOUa"} style={{background: "linear-gradient(216deg, rgba(255, 255, 255, 0) 97%, rgb(255, 255, 255) 109.00000000000001%, rgba(171, 171, 171, 0) 123%)", filter: "blur(10px)", mask: "url('https://framerusercontent.com/images/Pcw6GmZUb9oGBvflIVyiKXKvxY.svg') alpha no-repeat center / cover add", opacity: 0.4, WebkitFilter: "blur(10px)", WebkitMask: "url('https://framerusercontent.com/images/Pcw6GmZUb9oGBvflIVyiKXKvxY.svg') alpha no-repeat center / cover add"}} variants={{Ldsrs5v0b: {background: "linear-gradient(216deg, rgba(255, 255, 255, 0) -33%, rgb(255, 255, 255) -16%, rgba(171, 171, 171, 0) 6%)", opacity: 0.7}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jLCc9.framer-1pj2nd3, .framer-jLCc9 .framer-1pj2nd3 { display: block; }", ".framer-jLCc9.framer-109ttr2 { height: 383px; overflow: hidden; position: relative; width: 383px; }", ".framer-jLCc9 .framer-7995yt, .framer-jLCc9 .framer-nfnkyo { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; }", ".framer-jLCc9.framer-v-liwg20.framer-109ttr2 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 383px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 383
 * @framerIntrinsicWidth 383
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Ldsrs5v0b":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerodEPlxW1Q: React.ComponentType<Props> = withCSS(Component, css, "framer-jLCc9") as typeof Component;
export default FramerodEPlxW1Q;

FramerodEPlxW1Q.displayName = "Misc/Shimmer";

FramerodEPlxW1Q.defaultProps = {height: 383, width: 383};

addPropertyControls(FramerodEPlxW1Q, {variant: {options: ["muVlMopJH", "Ldsrs5v0b"], optionTitles: ["Start", "End"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerodEPlxW1Q, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})